<template>
   <div class="container">
      <div class="scanner-window" v-if="showScans">
         <div v-if="isDemo" class="mt-3" style="text-align: center; font-weight: 600; color: #db0000; animation: fadeIn 1.5s;"><img width=30 :src="$store.state.offlineIconSvg" /> You may proceed online or offline</div>
         <div v-else class="mt-3" style="text-align: center"><img width=30 :src="$store.state.offlineIconSvg" /> You may proceed online or offline</div>

         <div class="row justify-content-md-center mt-3 mb-2 pb-row">
            <div class=" col-12 col-lg-8">
               <div class="row justify-content-center">
                  <div class="col text-center progress-box pb-box" v-for="i in (performIntegrityCheck ? shareCount : shareThreshold)" v-bind:key="i">
                     <div class="pb-box-inner progress-box-active" v-if="i <= qrResults.length">
                        <h5>{{ i }}</h5>
                     </div>
                     <div class="pb-box-inner" v-else>
                        <h5>{{ i }}</h5>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div class="row mb-3 justify-content-center">
            &nbsp;<span class="text-danger">{{errorMessage}}</span>
            <span class="text-success">{{successMessage}}</span>
         </div>
         <div v-if="!isManualQrTextEntryMode" class="row">
            <video id="reader" width="100%" style="object-fit: cover; object-position: center; width: 350px; height: 300px; display: block; margin-left: auto; margin-right: auto"></video>
         </div>
         <div v-else class="row justify-content-md-center mt-3 mb-2 pb-row">
            <div class="col-12 mt-3 mb-5">
               <p>Enter Confidential Data:</p>
               <div>
                  <textarea @input="manualQrTextEntryChange()" style="width: 100%; height: 200px;" placeholder="Enter confidential text string retrieved by your scanner app" 
                     v-model="manualQrTextEntry" />
               </div>
               <div>
                  <div class="row justify-content-center justify-content-md-center mt-4">
                     <a href="#" @click="$event.preventDefault(); returnToScanner();">Return to scanner window</a>
                  </div>
               </div>
            </div>
         </div>


         <div v-if="qrResults.length == 0" align="center" style="margin-top: 15px">
            <form id="readerParallelForm" class="justify-content-md-center" v-if="!validateCreditId && !requireValidateAll">
               <input type="checkbox" class="form-check-input" id="exampleCheck1" v-model="performIntegrityCheck">
               <label class="" for="exampleCheck1">Perform validation on full QR code set</label>
            </form>
         </div>
      </div>

      <div class="results-window" v-else>
          <div class="row justify-content-md-center mt-4" v-if="!isTimerCleared">
             <div class="col-12 col-lg-6">
               <div class="form-group">
                 <label for="exampleFormControlTextarea1 info-2-label">Recovered Information</label>
                 <textarea v-model="resultText" class="form-control info-2" id="exampleFormControlTextarea1" rows="5" readonly=""></textarea>
               </div>
             </div>
           </div>

        <div class="row justify-content-md-center mt-4 recovery-cash">
          <div class="col-12 col-lg-6 text-center">
            <div class="">
              <h5>Screen and cache will clear in <span class="countdown" id="countdown-text"></span></h5>
            </div>
            <button @click="startTimer()" class="btn btn-orange btn-lg btn-block mt-4" id="reset-timer">Reset Timer</button>

            <button v-if="!isShowingNavigatePrintFinal2" @click="navigateHome()" class="btn btn-outline-primary btn-lg btn-block mt-4" id="reset-timer">Home</button>

            <button v-if="!isDemo && isShowingNavigatePrintFinal2" @click="clearSecret(); $router.push({name: 'print-final2', query: { credit: validateCreditId }})" class="btn btn-orange btn-lg btn-block mt-4">Next</button>

            <button v-if="isDemo && isShowingNavigatePrintFinal2" @click="clearSecret(); $router.push({name: 'demo-print-final2', query: { credit: validateCreditId }})" class="btn btn-orange btn-lg btn-block mt-4">Next</button>

          </div>
        </div>

         <div class="row justify-content-md-center mt-5">
            <div class="col-12 col-lg-6 text-center">
               <p style="padding: 30px; border: 1px solid gray">
                  Your QR codes remain active after scanning. Please destroy physical copies if you no longer wish to use them.
               </p>
            </div>
         </div>
      </div>

      <div v-if="isMobile && showScans && !isTimerCleared && !isManualQrTextEntryMode" class="row justify-content-center justify-content-md-center mt-4">
         <a href="#" @click="$event.preventDefault(); $bvModal.show('desktopAltValidateModal');">Problems scanning</a>
      </div>

      <div v-if="!isMobile && showScans && !isTimerCleared" class="row justify-content-center justify-content-md-center mt-4">
         <a href="#" @click="$event.preventDefault(); $bvModal.show('mobileAltValidateModal');">Problems scanning</a>
      </div>


      <b-modal class="modal fade" id="reminderModal" tabindex="-1" role="dialog" aria-labelledby="reminderModalLabel" aria-hidden="true" centered hide-footer>
         <template #modal-title>
            
         </template>
         <div class="modal-body">
            <h5 style="margin-top: -50px; margin-bottom: 20px;"><b>IMPORTANT</b></h5>

            <h5>1. Please validate each QR code in the set can be scanned using the wizard
            on this page before using them to store your confidential information</h5>

            <hr/>

            <h5>2. Confirm that each set ID on your printouts match</h5>
            <div class="col-12 col-lg-6">
                  <img style="max-height: 200px; max-width: 100%;" :src="qrSetImg1Png" alt="Xecret.io" />
            </div>

            <hr/>
            
            <h5>3. Confirm that all of the QR codes in the set have printed</h5>
            <div class="col-12 col-lg-6">
               <img style="max-height: 200px; max-width: 100%;" :src="qrSetImg2Png" alt="Xecret.io" />
            </div>
         </div>
      </b-modal>

      <b-modal class="modal fade" id="desktopAltValidateModal" tabindex="-1" role="dialog" aria-labelledby="reminderModalLabel" aria-hidden="true" centered hide-footer>
         <template #modal-title></template>
         <div class="modal-body">
            <div class="row justify-content-center ">
               <div class="col-12 col-lg-8">
                  <h5><span class="font-weight-bold">Problems Scanning?</span></h5>
                  <p>As a safety fallback, you can also use any QR Code reader app to scan the code, then transfer the data here. Simply copy and paste the data from a source of your choice.</p>
               </div>
               <div class="row mt-3">
                  <button class="btn btn-orange btn-lg btn-block" @click="$event.preventDefault; $bvModal.hide('desktopAltValidateModal');">Continue with Camera</button>
                  <button class="btn btn-orange btn-lg btn-block" @click="$event.preventDefault; $bvModal.hide('desktopAltValidateModal'); switchToRawText()">Enter Raw Data</button>
               </div>
            </div>
         </div>
      </b-modal>

      <b-modal class="modal fade" id="mobileAltValidateModal" tabindex="-1" role="dialog" aria-labelledby="reminderModalLabel" aria-hidden="true" centered hide-footer>
         <template #modal-title></template>
         <div class="modal-body">
            <div class="row justify-content-center ">
              <div class="col-12 col-lg-8 mt-4 mt-4">
                <h5><span class="font-weight-bold">Warning:&nbsp;</span> Your desktop camera may not be high enough resolution to scan the QR code. If it does not work, please use your
                mobile phone camera. <br><br>Go to <strong><span class="">{{standaloneValidateUrl}}</span></strong> on your mobile phone, or use the QR code below</h5>
                <div class="text-center mt-4">
                  <img :src="validateLinkQrDataString" alt="Qr code to recovery app" width="150">
                </div>
              </div>
            </div>
         </div>
      </b-modal>

      <!-- LEAVE THIS COMMENTED OUT <button @click="debugShowResults">Debug Show Results</button> -->

   </div>
  <!--END CONTAINER-->
</template>

<script>
import { unpack } from "@/serializer/binary";
import ARecoveryShare from "@/xecret-ida/recoveryshare";
import AXorIda from "@/xecret-ida/xorida";
import base45 from "base45";

import { kImgDataQrSetImg1, kImgDataQrSetImg2 } from "@/utils/offlineimg"

import QRCode from "qrcode";
import QrScanner from "qr-scanner";

import  { isMobile } from "@/utils/environ"

export default {
   name: "qrscan",
   computed: {
      isMobile() {
         return isMobile();
      },
      qrSetImg1Png() {
         return kImgDataQrSetImg1;
      },
      qrSetImg2Png() {
         return kImgDataQrSetImg2;
      },
      isDemo() {
         return location.href.includes("/demo");
      },
      demoPrefix() {
         return this.isDemo ? "/demo/" : "/";
      }
   },
   data() {
      return {
         qrResults: [],
         errorMessage: '',
         successMessage: '',
         performIntegrityCheck: false,
         resultText: '',
         codeReader: null,
         shareThreshold: 1,
         shareCount: 1,
         showScans: true,
         standaloneValidateUrl: "",
         validateCreditId: 0,
         validateLinkQrDataString: "",
         timerHandle: 0,
         dateMillisecTimeout: 0,
         timeRemainingText: "",
         isTimerCleared: false,
         isManualQrTextEntryMode: false,
         manualQrTextEntry: "",
         isShowingNavigatePrintFinal2: false,
         requireValidateAll: false,
         isCreatingSet: false
      };
   },
   beforeDestroy() {
      this.clearSecret();
   },
   methods: {
      clearSecret() {
         if (this.timerHandle) {
            window.clearTimeout(this.timerHandle);
         }
         // Stop the camera feed
         this.codeReader.stop();
         this.isTimerCleared = true;
         this.$store.commit("clearShares");
         this.$store.commit("setActiveCreditId", "");
      },
      debugShowResults() {
         this.showScans = false;
         this.startTimer();
      },
      setDisappearingErrorMsg(errorMsg) {
         this.errorMessage = errorMsg;
         let that = this;
         setTimeout(() => that.errorMessage = "", 2000);
      },
      setDisappearingSuccessMsg(successMsg) {
         this.successMessage = successMsg;
         let that = this;
         setTimeout(() => that.successMessage = "", 2000);
      },
      decodedTextToSlice(shareText) {
         let isNewFormat = false;
         let requiredPrefix = this.getIdaPrefix();
         let foundPrefix = shareText.slice(0, requiredPrefix.length);
         if (foundPrefix == requiredPrefix) {
            shareText = shareText.slice(requiredPrefix.length);
            isNewFormat = true;
         }

         // Try to decode base45 
         let decodedBuffer = null;
         try {
            decodedBuffer = base45.decode(shareText);
            if (isNewFormat) {
               let wm = this.getWm();
               for (var i = 0; i < decodedBuffer.length; ++i) {
                  decodedBuffer[i] = decodedBuffer[i] ^ wm[i % wm.length];
               }
            }
         }
         catch(error) {
            console.error(error);
            this.setDisappearingErrorMsg("Invalid or unrecognized QR code.");
            return false;
         }

         // unpack shares and metadata
         const slice = unpack(decodedBuffer);
         return slice;
      },
      async onScanSuccess(decodedText) {
         decodedText = this.isValidSlice(decodedText);
         // Process potential scanned QR code, called back from ZXing scanner
         console.log(`onScanSuccess: ${decodedText}`);
         if (this.qrResults.length === 0) {
            if (decodedText) {
               this.qrResults.push(decodedText);
               const firstSlice = this.decodedTextToSlice(this.qrResults[0]);
               this.shareCount = firstSlice.n;
               this.shareThreshold = firstSlice.k;
               this.setDisappearingSuccessMsg("Slice successfully added.");
               if (this.isManualQrTextEntryMode) {
                  let that = this;
                  setTimeout(() => that.manualQrTextEntry = "", 300);
               }
            }
         }
         else if (!this.qrResults.includes(decodedText)) {
            // Heuristic: the UUID of the scanned slice must match that of the first valid slice
            if (decodedText) {
               this.errorMessage = "";
               const firstSlice = this.decodedTextToSlice(this.qrResults[0]);
               const newSlice = this.decodedTextToSlice(decodedText);
               if (
                  newSlice.UUID[0] !== firstSlice.UUID[0] &&
                  newSlice.UUID[1] !== firstSlice.UUID[1] &&
                  newSlice.UUID[2] !== firstSlice.UUID[2] &&
                  newSlice.UUID[3] !== firstSlice.UUID[3]
               ) {
                  this.setDisappearingErrorMsg("All slices must be from the same set.");
                  return;
               }
               else {
                  this.qrResults.push(decodedText);
                  this.setDisappearingSuccessMsg("Slice successfully added.");
                  if (this.isManualQrTextEntryMode) {
                     this.manualQrTextEntry = "";
                  }
               }
            }
         } 

         // Normal check (k of n) or integrity (n of n)
         if ((this.qrResults.length === this.shareThreshold && !this.performIntegrityCheck) || (this.qrResults.length === this.shareCount && this.performIntegrityCheck)) {
            const firstLength = this.qrResults[0].byteLength;
            if (!this.qrResults.every((buffer) => buffer.byteLength === firstLength)) {
               this.setDisappearingErrorMsg("All slices must be from the same set.");
               return;
            }
            let availableShares = [];
            let metadata = [];

            // Unpack shares and metadata
            const sharesRequired = this.performIntegrityCheck ? this.shareCount : this.shareThreshold;
            let sharesAdded = 0;
            this.qrResults.forEach((shareText) => {
               if (sharesAdded === sharesRequired) {
                  return;
               }

               let data = this.decodedTextToSlice(shareText);
               metadata.push(data)

               availableShares.push(new ARecoveryShare(data.shareId, data.payload));

               sharesAdded += 1;
            });

            // Recover and display the secret
            const ida = new AXorIda(this.shareThreshold, this.shareCount, 128);

            let roundTrip = null;

            if (this.performIntegrityCheck) {
               let decoded;
               for (let i = 0; i < this.shareCount + 1 - this.shareThreshold; ++i) {
                  try {
                     let tryAvailableShares = availableShares.slice(i, i + this.shareThreshold);

                     decoded = this.recoverSecretFromSlices(tryAvailableShares, ida);
                  }
                  catch(err) {
                     if (err.message) {
                        this.setDisappearingErrorMsg(err.message);
                     }
                     else {
                        this.setDisappearingErrorMsg("Failed integrity check.");
                     }
                     return;
                  }
                  roundTrip = decoded;
               }
               // mark credit as used/validated/completed
               this.$emit("hideWizard", "");
               this.$store.commit("setDidValidateAllShares", true);
               if (this.validateCreditId) {
                  await this.axios
                     .post("/api/mark-active-credit-complete", {credit_id: this.validateCreditId}, { withCredentials: true })
                     .then((response) => {
                        console.log(response);
                        return true;
                     })
                     .catch((error) => {
                        console.error(error);
                        return false;
                     });
                  // always navigate, since they could be offline by design
                  this.isShowingNavigatePrintFinal2 = true;
               }                     
               else if (this.isCreatingSet) {
                  this.isShowingNavigatePrintFinal2 = true;
               }
               // add id to global blacklist
               this.$store.commit('approveShareUUID', metadata[0].UUID);
            }
            else { // else if !this.performIntegrityCheck
               // Standard secret recovery
               let decoded;
               try {
                  availableShares = availableShares.slice(0, this.shareThreshold);
                  decoded = this.recoverSecretFromSlices(availableShares, ida);
               }
               catch(err) {
                  this.setDisappearingErrorMsg(err.message);
                  return;
               }
               roundTrip = decoded;
            }

            // stop video and show the hidden message
            console.log(`decode result: ${roundTrip}`);
            const text = new TextDecoder().decode(roundTrip);
            this.errorMessage = "";
            this.resultText = text;
            console.log("Recovered: " + text);
            this.codeReader.stop();
            this.showScans = false;
            this.startTimer();

            this.axios
               .post("/api/usage-counter", { }, { withCredentials: true, })
               .then(() => {
                  // ignore response
               })
               .catch((error) => console.error(error));
            
            if (this.isDemo) {
               window.gtag("event", "Demo/Recover/Complete");
            }
            else {
               window.gtag("event", "Paid/Recover/Complete");  
            }
         }
      },
      recoverSecretFromSlices(availableShares, ida) {
         // Generate recovery matrix and recover secret
         const shareIndexes = availableShares.map((s) => s.ShareIdx);
         const recoveryMatrix = ida.GenerateRecoveryMatrix(shareIndexes);
         const roundTrip = ida.RecoverSecret(recoveryMatrix, availableShares);

         return roundTrip;
      },
      manualQrTextEntryChange() {
         this.onScanSuccess(this.manualQrTextEntry);
      },
      isUUIDIntegrityChecked(UUID) {
         // check if we have already approved this uuid
         let allUUIDs = this.$store.state.verifiedShareUUIDs;

         if (allUUIDs === null) return false;

         for (let i = 0; i < allUUIDs.length; ++i) {
            if (
               allUUIDs[i][0] === UUID[0] &&
               allUUIDs[i][1] === UUID[1] &&
               allUUIDs[i][2] === UUID[2] &&
               allUUIDs[i][3] === UUID[3]
            ) {
               return true;
            }
         }

         return false;
      },
      equalUint8Array(a, b) {
         // equal if a and b have the same length and values
         if (a.byteLength != b.byteLength) {
            return false;
         }
         for (let i = 0; i != a.byteLength; ++i) {
            if (a[i] != b[i]) return false;
         }
         return true;
      },
      getIdaPrefix() {
         return "Xecret (TM) -> PRIVATE.ME (R) -> IDA5 -> Encrypted://5";
      },
      getWm() {
         return [88, 101, 99, 114, 101, 116, 32, 40, 84, 77, 41];
      },
      isValidSlice(shareText) {
         // Determine if the provided QR Code text is a valid slice, returns result as bool

         const isFirstSlice = this.qrResults.length == 0;

         let isNewFormat = false;
         let requiredPrefix = this.getIdaPrefix();
         let foundPrefix = shareText.slice(0, requiredPrefix.length);
         if (foundPrefix === requiredPrefix) {
            isNewFormat = true;
         }

         // Try to decode base45 
         let decodedBuffer = null;
         try {
            if (isNewFormat) {
               decodedBuffer = base45.decode(shareText.slice(requiredPrefix.length));
               let wm = this.getWm();
               for (var i = 0; i < decodedBuffer.length; ++i) {
                  decodedBuffer[i] = decodedBuffer[i] ^ wm[i % wm.length];
               }
            }
            else {
               decodedBuffer = base45.decode(shareText);
            }
         }
         catch(error) {
            console.log(foundPrefix);
            console.error(error);
            this.setDisappearingErrorMsg("Error processing QR code.");
            return false;
         }

         // unpack shares and metadata
         const slice = unpack(decodedBuffer);

         // slice checks
         if (slice.magicNumber !== this.$store.state.magicNumber) {
            this.setDisappearingErrorMsg(`Unrecognized QR code. (${isNewFormat}; ${slice.magicNumber})`);
            return false;
         }
         else if (isFirstSlice && slice.n < 3) {
            this.setDisappearingErrorMsg("Invalid slice, bad n.");
            return false;
         }
         else if (isFirstSlice && slice.k < 2) {
            this.setDisappearingErrorMsg("Invalid slice, bad k.");
            return false;
         }
         else if (!isFirstSlice && slice.n !== this.shareCount) {
            this.setDisappearingErrorMsg("Invalid slice, bad n.");
            return false;
         }
         else if (!isFirstSlice && slice.k !== this.shareThreshold) {
            this.setDisappearingErrorMsg("This QR code does not belong to this set.");
            return false;
         }
         else if (!isFirstSlice && slice.shareId >= this.shareCount) {
            this.setDisappearingErrorMsg("Invalid slice, bad share id.");
            return false;
         }

         return shareText; 
      },
      returnToScanner() {
         this.isManualQrTextEntryMode = false;
         let that = this;
         setTimeout(() => that.reopenScanner(), 300);
         this.reopenScanner();
      },
      switchToRawText() {
         this.isManualQrTextEntryMode = true;
         this.codeReader.stop();
      },
      startTimer() {
         console.log("startTimer");
         if (this.timerHandle) {
            window.clearTimeout(this.timerHandle);
         }
         this.isTimerCleared = false;
         let millisecRemaining = 60 * 3 * 1000;
         if (this.$route.query.dbg) {
            millisecRemaining = 5000;
         }
         let that = this;
         let nowMillisec = Date.now();
         this.dateMillisecTimeout = nowMillisec + millisecRemaining;

         this.timerHandle = setInterval(function (){
            let ticksRemaining = that.dateMillisecTimeout - Date.now();
            if (ticksRemaining <= 0) {
               that.isTimerCleared = true;
               that.$store.commit("clearShares");
               that.$store.commit("setActiveCreditId", "");
               console.log(location.href);
               console.log(that.isDemo);
               if (!that.isCreatingSet) {
                  window.location.assign(that.isDemo ? "/demo" : "/");
               }
               else {
                  window.location.assign(that.isDemo ? "/demo/print-final2" : "/print-final2");
               }
               window.clearTimeout(that.timerHandle);
               document.getElementById("countdown-text").innerText = "";
               return;
            }
            let minutes = Math.floor(ticksRemaining / 60 / 1000);
            let seconds = Math.floor((ticksRemaining - minutes * 60 * 1000) / 1000);
            that.timeRemainingText = `${minutes}:` + `${seconds}`.padStart(2, '0');
            document.getElementById("countdown-text").innerText = that.timeRemainingText;
         }, 200);
      },
      navigateHome() {
         this.clearSecret();
         let homeUrl = this.isDemo ? "/demo" : "/";
         console.log("Navigating to " + homeUrl);
         window.location.assign(homeUrl);
      },
      reopenScanner() {
         if (this.codeReader) {
            try {
               this.codeReader.stop();
            }
            catch {
               console.log("Error stopping code reader");
            }

            this.codeReader = null;
         }


         let that = this;
         setTimeout(() => {
            // Setup ZXing QR code scanner and get user camera
            const previewElem = document.querySelector('video');

            that.codeReader = new QrScanner(
             previewElem,
             (result) => {
               console.log(`scan result.data = ${result.data}`);
               // properly decoded qr code
               that.onScanSuccess(result.data);
             },
             // scanner options
             {
               preferredCamera: 'environment',
               maxScansPerSecond: 2,
               calculateScanRegion: (video) => {
                   console.log(`videoWidth=${video.videoWidth}`);
                   console.log(`videoHeight=${video.videoHeight}`);
                  var maxDimension = Math.min(video.videoWidth, video.videoHeight);
                  return {
                     x: (video.videoWidth - maxDimension) / 2,
                     y: (video.videoHeight - maxDimension) / 2,
                     width: maxDimension,
                     height: maxDimension,
                     downScaledWidth: maxDimension,
                     downScaledHeight: maxDimension,
                  };
                },
                highlightScanRegion: !that.isMobile,
                highlightCodeOutline: true,
             });
            that.codeReader.start();
         }, 500);
      },
   },
   unmounted() {
      window.clearTimeout(this.timerHandle);
      this.isTimerCleared = true;
      this.$store.commit("clearShares");
      this.$store.commit("setActiveCreditId", "");
   },
   async mounted() {
      this.performIntegrityCheck = false;
      this.validateCreditId = 0;

      if (location.href.includes("/validate")) {
         this.requireValidateAll = true;
         this.performIntegrityCheck = true;
         this.isCreatingSet = true;
      }

      if (this.timerHandle) {
         window.clearTimeout(this.timerHandle);
      }
      this.$store.commit("setDidValidateAllShares", false);

      if (this.$route.query.c /* creating a new set */) {
         this.isCreatingSet = true;
      }


      if (this.$route.query.credit) {
         this.validateCreditId = this.$route.query.credit;
         this.performIntegrityCheck = true;
      }
      else if (this.$store.state.activeCreditId) {
         this.validateCreditId = this.$store.state.activeCreditId;
         this.$store.commit("setActiveCreditId", 0);
         this.performIntegrityCheck = true;
      }
      this.$store.commit("setValidateCreditId", this.validateCreditId);

      if (this.validateCreditId) {
         this.standaloneValidateUrl = "https://" + window.location.host + "/validateset?credit=" + this.validateCreditId + "&c=1";
      } else {
         this.standaloneValidateUrl = "https://" + window.location.host + "/recover";
      }

      this.reopenScanner();

      // need to create QR code graphic to help desktop users
      this.validateLinkQrDataString = await QRCode.toDataURL(this.standaloneValidateUrl);

      if (!location.href.includes("recover")) {
         this.$bvModal.show('reminderModal');
      }

      if (this.isDemo) {
         window.gtag("event", "Demo/Recover/Begin");
      }
      else {
         window.gtag("event", "Paid/Recover/Begin");  
      }
      
   }
};
</script>
